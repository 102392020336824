<template>
  <div>
    <div class="title">
      <div class="title_t" style="margin-bottom: 0;">
        <span>司机认证审核</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息审核" name="first"></el-tab-pane>
        <el-tab-pane label="服务项目审核" name="second"></el-tab-pane>
      </el-tabs>
    </div>

    <el-form ref="form" :model="form" label-width="120px">
      <el-card shadow="never" style="margin-top: 20px;">
        <el-row :gutter="20">
          <el-col :span="8">
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 20px;font-weight: 700;color: #202033;">身份证信息</span>
              </div>
              <div>
                <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>
                <el-button>审核不通过</el-button>
              </div>
            </div>
            <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
              <el-form-item label="个人姓名:">
                <el-input
                    clearable
                    v-model="form.facilitator_name"
                    :disabled="true"
                    placeholder="请输入个人姓名"/>
              </el-form-item>
              <el-form-item label="身份证号:">
                <el-input
                    clearable
                    v-model="form.facilitator_name"
                    :disabled="true"
                    placeholder="请输入身份证号"/>
              </el-form-item>
              <el-row :gutter="15" style="margin-bottom: 15px;">
                <el-col :span="12">
                  <img src="@/assets/images/home/upload_bg.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
                <el-col :span="12">
                  <img src="@/assets/images/home/fanmian.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
              </el-row>
              <el-form-item label="手机号码:">
                <el-input
                    clearable
                    v-model="form.facilitator_name"
                    :disabled="true"
                    placeholder="请输入手机号码"/>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="8">
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 20px;font-weight: 700;color: #202033;">驾驶证信息</span>
              </div>
              <div>
                <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>
                <el-button>审核不通过</el-button>
              </div>
            </div>
            <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
              <el-row :gutter="15" style="margin-bottom: 15px;">
                <el-col :span="12">
                  <img src="@/assets/images/home/jiashizheng.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
                <el-col :span="12">
                  <img src="@/assets/images/home/jiashizhengfanmian.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
              </el-row>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 20px;font-weight: 700;color: #202033;">行驶证信息</span>
              </div>
              <div>
                <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>
                <el-button>审核不通过</el-button>
              </div>
            </div>
            <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
              <el-row :gutter="15" style="margin-bottom: 15px;">
                <el-col :span="12">
                  <img src="@/assets/images/home/jiashizheng.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
                <el-col :span="12">
                  <img src="@/assets/images/home/jiashizhengfanmian.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
              </el-row>
            </div>
          </el-col>

          <el-col :span="8">
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 20px;font-weight: 700;color: #202033;">车辆信息</span>
              </div>
              <div>
                <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>
                <el-button>审核不通过</el-button>
              </div>
            </div>
            <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
              <el-form-item label="车牌号:">
                <el-input
                    clearable
                    v-model="form.facilitator_name"
                    :disabled="true"
                    placeholder="请输入车牌号"/>
              </el-form-item>
              <el-form-item label="车架号:">
                <el-input
                    clearable
                    v-model="form.facilitator_name"
                    :disabled="true"
                    placeholder="请输入车架号"/>
              </el-form-item>
              <el-form-item label="车辆类型:">
                <el-input
                    clearable
                    v-model="form.facilitator_name"
                    :disabled="true"
                    placeholder="请输入车辆类型"/>
              </el-form-item>
              <el-form-item label="车辆型号:">
                <el-input
                    clearable
                    v-model="form.facilitator_name"
                    :disabled="true"
                    placeholder="请输入车辆型号"/>
              </el-form-item>
              <el-row :gutter="15" style="margin-bottom: 15px;">
                <el-col :span="12">
                  <img src="@/assets/images/home/upload_bg.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
                <el-col :span="12">
                  <img src="@/assets/images/home/fanmian.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 20px;font-weight: 700;color: #202033;">保单信息</span>
              </div>
              <div>
                <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>
                <el-button>审核不通过</el-button>
              </div>
            </div>
            <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
              <el-row :gutter="15" style="margin-bottom: 15px;">
                <el-col :span="12">
                  <img src="@/assets/images/home/jiashizheng.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
                <el-col :span="12">
                  <img src="@/assets/images/home/jiashizhengfanmian.png" alt="" style="height: 130px;width: 100%;">
                </el-col>
              </el-row>
            </div>
          </el-col>

          <el-col :span="16" style="margin-top: 20px;">
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 10px;">价格信息</span>
                <span style="font-size: 14px;font-weight: 400;color: #4e4e4e;margin-right: 10px;">你已选择：</span>
                <span style="font-size: 14px;font-weight: 400;color: #fea837;">重庆市酉阳县</span>
              </div>
              <div>
                <el-button style="background: #202033!important;border-color: #202033!important;color: #FFFFFF!important;">审核通过</el-button>
                <el-button>审核不通过</el-button>
              </div>
            </div>
            <div style="margin-top: 15px;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="事故救援一口价:">
                    <el-input
                        clearable
                        v-model="form.facilitator_name"
                        :disabled="true"
                        placeholder="请输入价格"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="故障救援一口价:">
                    <el-input
                        clearable
                        v-model="form.facilitator_name"
                        :disabled="true"
                        placeholder="请输入价格"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-form-item label="公式价:">
                  <el-input
                      style="display: inline-block; width: 180px;margin-right: 20px;"
                      clearable
                      v-model="form.facilitator_name"
                      :disabled="true"
                      placeholder="请输入起步价"/>
                  <el-input
                      style="display: inline-block; width: 180px;margin-right: 20px;"
                      clearable
                      v-model="form.facilitator_name"
                      :disabled="true"
                      placeholder="请输入公里数"/>
                  <el-input
                      style="display: inline-block; width: 180px;"
                      clearable
                      v-model="form.facilitator_name"
                      :disabled="true"
                      placeholder="请输入超出公里价"/>
                </el-form-item>
              </el-row>
            </div>
          </el-col>

        </el-row>

      </el-card>
    </el-form>

    <!--按钮-->
    <div class="card_bottom">
      <el-button style="width: 200px !important;" @click="cancellation()">取消</el-button>
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;">提交</el-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'index',
  // 支柱道具属性
  props: {

  },
  // 组件生效
  components: {},
  // Data数据
  data() {
    return {
      form:{},

      activeName: 'first'

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {

  },
  // 方法集合
  methods: {
    //返回按钮
    returnPage(){
      this.$emit('showtoexamine',false) ;
    },
    //取消按钮
    cancellation(){
      this.$emit('showtoexamine',false) ;
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },

  },
}
</script>

<style scoped lang="scss">
.span_l{
  display: inline-block;
  width: 128px;
  text-align: right;
}
.span_r{
  font-size: 15px !important;
}
</style>
